'use client'

import * as React from 'react'
import { useTranslations } from 'next-intl'
import { IconButton } from 'primitives/buttons/icon-button'
import { tw } from 'utils/classnames'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'

import { LoginIcon } from '@ask-cf/assets/icons'

export function LoginButton({
  className,
  spanClassName,
}: {
  className?: string
  spanClassName?: string
}) {
  const t = useTranslations('ask_cf.components.login_button')
  const { openModal } = useUserAuthModal()

  return (
    <IconButton
      variant="transparent"
      onClick={() => openModal('login')}
      className={tw('text-text-tertiary hover:text-blue-700', className)}
    >
      <LoginIcon />
      <span className={tw('text-md ml-1 leading-6', spanClassName)}>{t('label')}</span>
    </IconButton>
  )
}
