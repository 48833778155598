'use client'

import * as React from 'react'
import { useTranslations } from 'next-intl'
import { tw } from 'utils/classnames'
import { IconButton } from 'primitives/buttons/icon-button'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'

import { AccountCircleIcon } from '@ask-cf/assets/icons'

export function SignUpButton({
  className,
  spanClassName,
}: {
  className?: string
  spanClassName?: string
}) {
  const t = useTranslations('ask_cf.components.sign_up_button')
  const { openModal } = useUserAuthModal()

  return (
    <IconButton
      variant="transparent"
      onClick={() => openModal()}
      className={tw('text-text-tertiary  hover:text-blue-700', className)}
      aria-label={t('label')}
    >
      <AccountCircleIcon />
      <span
        className={tw(
          'text-md ml-1 whitespace-nowrap leading-6 hover:hover:text-blue-700',
          spanClassName,
        )}
      >
        {t('label')}
      </span>
    </IconButton>
  )
}
